<template>
  <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-tabs
        centered
        class="ml-n9"
        color="grey darken-1"
      >
        <v-tab
          v-for="link in links"
          :key="link"
          @click="cargarComponente(link)"
        >
          {{ link }}
        </v-tab>
      </v-tabs>

    </v-app-bar>

    <v-main>
      <calidad-del-aire v-if="mostrarCalidadAire"/>
      <temperatura v-if="mostrarTemperatura"/> 
      <humedad v-if="mostrarHumedad"/>
      <contaminacion-acustica v-if="mostrarContaminacionAcustica"/>
    </v-main>
  </v-app>
</template>

<script>
import CalidadDelAire from './components/CalidadDelAire'
import Temperatura from './components/Temperatura'
import Humedad from './components/Humedad'
import ContaminacionAcustica from './components/ContaminacionAcustica'

export default {
  name: 'MedidorMovilFrontEnd',

  components: {
    CalidadDelAire,
    Temperatura,
    Humedad,
    ContaminacionAcustica
  },

    data: () => ({
    mostrarCalidadAire: true,
    mostrarContaminacionAcustica: false,
    mostrarHumedad: false,
    mostrarTemperatura: false,
    links: [
        'Calidad del aire',
        'Temperatura',
        'Humedad',
        'Contaminación acústica',
      ],
  }),

  methods: {
    cargarComponente: function (componente){
      this.mostrarCalidadAire = false
      this.mostrarTemperatura = false
      this.mostrarHumedad = false
      this.mostrarContaminacionAcustica = false
      if (componente.includes("aire")){
        this.mostrarCalidadAire = true
      } else if (componente.includes("Temperatura")){
        this.mostrarTemperatura = true
      } else if (componente.includes("Humedad")){
        this.mostrarHumedad = true
      } else {
        this.mostrarContaminacionAcustica = true
      }
    }
  }

};
</script>
