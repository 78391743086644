<template>
  <div id="app">
    <v-app id="inspire">
      <div align="center" justify="space-around">
        <br />
        <p class="text-center">Seleccione las fechas para el mapa de calor</p>
        <!-- Esta v-col contiene los elementos necesarios para la selección de la fecha inicial. -->
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menuInicio"
            v-model="menuInicio"
            :close-on-content-click="false"
            :return-value.sync="fechaInicio"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaInicio"
                label="Fecha de inicio"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaInicio"
              no-title
              scrollable
              locale="es"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.menuInicio.save(fechaInicio)"
              >
                OK
              </v-btn>
              <v-btn text color="primary" @click="menuInicio = false">
                Cancelar
              </v-btn>
            </v-date-picker>
          </v-menu>
        <!-- Esta v-col contiene los elementos necesarios para la selección de la fecha final. -->
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menuFin"
            v-model="menuFin"
            :close-on-content-click="false"
            :return-value.sync="fechaFin"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fechaFin"
                label="Fecha de fin"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fechaFin" no-title scrollable locale="es">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.menuFin.save(fechaFin)">
                OK
              </v-btn>
              <v-btn text color="primary" @click="menuFin = false">
                Cancelar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <!-- Esta v-col contiene tanto el mapa como la leyenda asociada a este. -->
        <v-col cols="12" sm="10">
          <div id="mapa" style="height: 1000px">
            <div class="leyenda">
              <h4>Calidad del aire<br/>(ppm)</h4>
              <div><span style="background-color: #00ff00"></span>&lt;250</div>
              <div><span style="background-color: #80ff00"></span>250 - 500</div>
              <div><span style="background-color: #ffff00"></span>500 - 750</div>
              <div><span style="background-color: #ff8000"></span>750 - 1000</div>
              <div><span style="background-color: #ff0000"></span>+1000</div>
            </div>
          </div>
        </v-col>
        <footer>
          Universidad de Jaén -- Trabajo realizado por Jesús Ojeda García, Miguel Ángel González Gallardo
          y Juan Francisco Molina Martos
        </footer>
      </div>
    </v-app>
  </div>
</template>

<style>
    .leyenda {
        background-color: #000;
        color: #fff;
        border-radius: 3px;
        bottom: 30px;
        box-shadow: 0 1px 2px rgba(255, 255, 255, 0.13);
        font: 16px/22px 'Helvetica Neue', Arial, Helvetica, sans-serif;
        padding: 10px;
        position: absolute;
        right: 10px;
        z-index: 1;
    }

    .leyenda h4 {
        margin: 0 0 10px;
    }

    .leyenda div span {
        border-radius: 50%;
        display: inline-block;
        height: 15px;
        margin-right: 5px;
        width: 15px;
    }
</style>

<script>
import mapboxgl from "mapbox-gl";
export default {
  name: "CalidadDelAire",
  data: () => ({
    fechaInicio: "",
    fechaFin: "",
    menuInicio: false,
    menuFin: false,
  }),
  methods: {
    iniciarMapa() {
      mapboxgl.accessToken = 'pk.eyJ1IjoianVhbmZyYWxveGkiLCJhIjoiY2tvZWc1NjhwMGJzdzJudHIzYTZrcGhqcyJ9.2fPK-GDqNZ3D3o9Evfk3NA';
      const axios = require("axios");
      // Se hace una petición al servidor y, una vez recibidos los datos, se procesan los datos y se añaden al mapa.
      var cadenaPeticion = "https://controldeairemovilbackend.azurewebsites.net/api/valoressensores/calidadAire";
      if (this.fechaInicio != ""){
        cadenaPeticion += "?fechaInicial=" + this.fechaInicio;
      }
      if(this.fechaFin != ""){
        if (!cadenaPeticion.includes("?")){
          cadenaPeticion += "?fechaFinal=" + this.fechaFin;
        } else {
          cadenaPeticion += "&fechaFinal=" + this.fechaFin;
        }
      }
      axios
        .get(
          cadenaPeticion
        )
        .then(function (response) {
          var datos = response.data;
          var datosProcesados = []
          var numDatos = datos.length - 1;
          while (numDatos >= 0) {
            var colorPunto = "";
            if (datos[numDatos].calidadAire < 250) {
              colorPunto = "Nivel1";
            } else if (datos[numDatos].calidadAire >= 250 && datos[numDatos].calidadAire < 500){
              colorPunto = "Nivel2";
            } else if (datos[numDatos].calidadAire >= 500 && datos[numDatos].calidadAire < 750) {
              colorPunto = "Nivel3";
            } else if (datos[numDatos].calidadAire >= 750 && datos[numDatos].calidadAire < 1000){
              colorPunto = "Nivel4";
            } else {
              colorPunto = "Nivel5";
            }
            var objetoProcesado = {
              "type": "Feature",
              "properties": {
                "color": colorPunto
              },
              "geometry": {
                "type": "Point",
                "coordinates": [datos[numDatos].longitud,datos[numDatos].latitud]
              }
            }
            datosProcesados.push(objetoProcesado);
            numDatos = numDatos - 1;
          }
          // Se crea el mapa.
          window.mapaAire = new mapboxgl.Map({
            container: 'mapa',
            style: 'mapbox://styles/mapbox/dark-v10',
            zoom: 15,
            center: [-3.774167, 37.786111]
          });
          // Se añade un conjunto de datos y se indica que se ha de dibujar un círculo por dato.
          window.mapaAire.on('load', function(){
            window.mapaAire.addSource('datosMapaAire',
              {
                  "type": "geojson",
                  "data": {
                    "type": "FeatureCollection",
                    "features": datosProcesados
                  }
              }
            );
            window.mapaAire.addLayer({
              'id': 'capaMapa',
              'type': 'circle',
              'source': 'datosMapaAire',
              'paint': {
                  'circle-radius': {
                      'base': 1.75,
                      'stops': [
                          [12, 2],
                          [22, 180]
                      ]
                  },
                  'circle-color': [
                      'match',
                      ['get', 'color'],
                      'Nivel1',
                      '#00ff00',
                      'Nivel2',
                      '#80ff00',
                      'Nivel3',
                      '#ffff00',
                      'Nivel4',
                      '#ff8000',
                      'Nivel5',
                      '#ff0000',
                      '#ccc'
                  ]
              }
            });
          })
        })
        .finally(
          setTimeout(this.recargarDatos,10000)
        )
    },
    recargarDatos() {
      const axios = require("axios");
      // Se hace una petición al servidor y, una vez recibida, se procesan los datos y se añaden al mapa.
      var cadenaPeticion = "https://controldeairemovilbackend.azurewebsites.net/api/valoressensores/calidadAire";
      if (this.fechaInicio != ""){
        cadenaPeticion += "?fechaInicial=" + this.fechaInicio;
      }
      if(this.fechaFin != ""){
        if (!cadenaPeticion.includes("?")){
          cadenaPeticion += "?fechaFinal=" + this.fechaFin;
        } else {
          cadenaPeticion += "&fechaFinal=" + this.fechaFin;
        }
      }
      axios
        .get(
          cadenaPeticion
        )
        .then(function(response){
          var datos = response.data;
          var datosProcesados = []
          var numDatos = datos.length - 1;
          while (numDatos >= 0) {
            var colorPunto = "";
            if (datos[numDatos].calidadAire < 250) {
              colorPunto = "Nivel1";
            } else if (datos[numDatos].calidadAire >= 250 && datos[numDatos].calidadAire < 500){
              colorPunto = "Nivel2";
            } else if (datos[numDatos].calidadAire >= 500 && datos[numDatos].calidadAire < 750) {
              colorPunto = "Nivel3";
            } else if (datos[numDatos].calidadAire >= 750 && datos[numDatos].calidadAire < 1000){
              colorPunto = "Nivel4";
            } else {
              colorPunto = "Nivel5";
            }
            var objetoProcesado = {
              "type": "Feature",
              "properties": {
                "color": colorPunto
              },
              "geometry": {
                "type": "Point",
                "coordinates": [datos[numDatos].longitud,datos[numDatos].latitud]
              }
            }
            datosProcesados.push(objetoProcesado);
            numDatos = numDatos - 1;
          }
          // Se añaden los datos al conjunto de datos.
          window.mapaAire.getSource('datosMapaAire').setData({
            "type": "FeatureCollection",
            "features": datosProcesados     
          });
        })
        .finally(
          setTimeout(this.recargarDatos,10000)
        )
    },
    
  },
  mounted() {
    this.iniciarMapa();
  },
};
</script>